import autoBind from 'auto-bind';
import classNames from 'classnames';
import { filter, includes, map } from 'lodash-es';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import MultiRoom from '../../public/images/layouts/multi-room.svg';
import RadiatorSVG from '../../public/images/layouts/radiator.svg';
import HomeSVG from '../../public/images/layouts/recommended.svg';
import SingleRoom from '../../public/images/layouts/single-room.svg';
import type { RootState } from '../../redux/slice';
import { formActions, getLayoutType, getProjectFilter } from '../../redux/slice/formData';
import { getSupportedLayoutsOverride } from '../../redux/slice/ui';
import { checkAndUpdateProject } from '../../redux/thunks';
import { LAYOUT_TYPE_CONTENT_OVERRIDE_KEYS, LAYOUT_TYPE_LABELS } from '../../utils/constants';
import { DuctedDeliveryType, LayoutType, ProjectFilter } from '../../utils/enums';
import FilterUtil from '../../utils/filterUtil';
import TrackingUtil from '../../utils/trackingUtil';
import TypeSelector, { TypeOption } from '../common/TypeSelector';

type LayoutSelectorOwnProps = {
  className?: string;
};

function mapStateToProps(state: RootState) {
  return {
    value: getLayoutType(state),
    projectFilter: getProjectFilter(state),
    supportedLayoutsOverride: getSupportedLayoutsOverride(state),
  };
}

const mapDispatchToProps = {
  updateLayoutType: formActions.updateLayoutType,
  updateDuctedDelivery: formActions.updateDuctedDelivery,
  checkAndUpdateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type LayoutSelectorProps = PropsFromRedux & LayoutSelectorOwnProps;

class LayoutSelector extends React.PureComponent<LayoutSelectorProps> {
  constructor(props: LayoutSelectorProps) {
    super(props);
    autoBind(this);
  }

  componentDidMount(): void {
    this.applySupportedLayoutTypes();
  }

  componentDidUpdate(prevProps: Readonly<LayoutSelectorProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.applySupportedLayoutTypes();
  }

  private applySupportedLayoutTypes() {
    const supportedLayoutTypes = this.determinedSupportedLayoutTypes();

    if (supportedLayoutTypes.length > 0) {
      const { value } = this.props;
      if (!includes(supportedLayoutTypes, value)) {
        this.handleLayoutChange(supportedLayoutTypes[0], { doNotTrack: true });
      }
    }
  }

  handleLayoutChange(value: LayoutType, config?: { doNotTrack?: boolean }) {
    const { updateLayoutType, updateDuctedDelivery, checkAndUpdateProject } = this.props;
    updateLayoutType(value);

    if (includes([LayoutType.MULTIPLE_ROOMS, LayoutType.SINGLE_ROOM], value)) {
      updateDuctedDelivery(DuctedDeliveryType.NO);
    }

    if (!config?.doNotTrack) {
      TrackingUtil.formUpdate('layoutType', value);
    }

    checkAndUpdateProject();
  }

  getOption(type: LayoutType, svg: React.ReactElement): TypeOption<LayoutType> | undefined {
    const label = LAYOUT_TYPE_LABELS[type];
    if (!label) return undefined;

    return { type, label, svg };
  }

  buildOptionForLayoutType(layoutType: LayoutType) {
    switch (layoutType) {
      case LayoutType.WHOLE_HOME:
        return this.getOption(LayoutType.WHOLE_HOME, <HomeSVG />);
      case LayoutType.MULTIPLE_ROOMS:
        return this.getOption(LayoutType.MULTIPLE_ROOMS, <MultiRoom />);
      case LayoutType.SINGLE_ROOM:
        return this.getOption(LayoutType.SINGLE_ROOM, <SingleRoom />);
      case LayoutType.BOILER:
        return this.getOption(LayoutType.BOILER, <RadiatorSVG />);
      default:
        return undefined;
    }
  }

  determinedSupportedLayoutTypes(): LayoutType[] {
    const { supportedLayoutsOverride } = this.props;
    if (supportedLayoutsOverride && supportedLayoutsOverride.length > 0) {
      const layoutTypes = FilterUtil.filterListUndefined(
        map(supportedLayoutsOverride, (key) => LAYOUT_TYPE_CONTENT_OVERRIDE_KEYS[key]),
      );

      const updatedLayoutTypes = this.filterLayoutTypesForProjectFilter(layoutTypes);
      return updatedLayoutTypes;
    }

    return [LayoutType.WHOLE_HOME, LayoutType.MULTIPLE_ROOMS, LayoutType.SINGLE_ROOM];
  }

  filterLayoutTypesForProjectFilter(layoutTypes: LayoutType[]): LayoutType[] {
    const { projectFilter } = this.props;
    if (projectFilter !== ProjectFilter.COOL_ONLY) return layoutTypes;

    return filter(layoutTypes, (layoutType) => layoutType !== LayoutType.BOILER);
  }

  getOptions() {
    const supportedLayoutTypes = this.determinedSupportedLayoutTypes();
    return filter(supportedLayoutTypes.map(this.buildOptionForLayoutType)) as TypeOption<LayoutType>[];
  }

  render() {
    const { value, className } = this.props;
    const options = this.getOptions();
    if (!options || options.length <= 1) return null;

    return (
      <div className={classNames('LayoutSelector', className)}>
        <TypeSelector
          groupId="layoutType"
          label="What type of heating or A/C are you looking for?"
          value={value}
          options={options}
          onChange={this.handleLayoutChange}
        />
      </div>
    );
  }
}

export default connector(LayoutSelector);
