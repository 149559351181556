import autoBind from 'auto-bind';
import classNames from 'classnames';
import { filter, includes, map } from 'lodash-es';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import HeatOnly from '../../public/images/layouts/air-heat-filled.svg';
import HeatAndCool from '../../public/images/layouts/heat-cool.svg';
import CoolOnly from '../../public/images/layouts/smoke.svg';
import type { RootState } from '../../redux/slice';
import { formActions, getProjectFilter } from '../../redux/slice/formData';
import { getSupportedProjectFiltersOverride } from '../../redux/slice/ui';
import { checkAndUpdateProject } from '../../redux/thunks';
import { PROJECT_FILTER_CONTENT_OVERRIDE_KEYS, PROJECT_FILTER_LABELS } from '../../utils/constants';
import { ProjectFilter } from '../../utils/enums';
import FilterUtil from '../../utils/filterUtil';
import TrackingUtil from '../../utils/trackingUtil';
import TypeSelector, { TypeOption } from '../common/TypeSelector';

type ProjectFilterSelectorSelectorOwnProps = {
  className?: string;
};

function mapStateToProps(state: RootState) {
  return {
    value: getProjectFilter(state),
    supportedProjectFiltersOverride: getSupportedProjectFiltersOverride(state),
  };
}

const mapDispatchToProps = {
  updateProjectFilter: formActions.updateProjectFilter,
  checkAndUpdateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ProjectFilterSelectorProps = PropsFromRedux & ProjectFilterSelectorSelectorOwnProps;

class ProjectFilterSelector extends React.PureComponent<ProjectFilterSelectorProps> {
  constructor(props: ProjectFilterSelectorProps) {
    super(props);
    autoBind(this);
  }

  componentDidMount(): void {
    this.applySupportedProjectFilters();
  }

  componentDidUpdate(prevProps: Readonly<ProjectFilterSelectorProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.applySupportedProjectFilters();
  }

  private applySupportedProjectFilters() {
    const supportedProjectFilters = this.determinedSupportedProjectFilters();

    if (supportedProjectFilters.length < 3) {
      const { value } = this.props;
      if (!includes(supportedProjectFilters, value)) {
        this.handleProjectFilterChange(supportedProjectFilters[0], { doNotTrack: true });
      }
    }
  }

  handleProjectFilterChange(value: ProjectFilter, config?: { doNotTrack?: boolean }) {
    const { updateProjectFilter, checkAndUpdateProject } = this.props;
    updateProjectFilter(value);

    if (!config?.doNotTrack) {
      TrackingUtil.formUpdate('projectFilter', value);
    }

    checkAndUpdateProject();
  }

  getOption(type: ProjectFilter, svg: React.ReactElement): TypeOption<ProjectFilter> | undefined {
    const label = PROJECT_FILTER_LABELS[type];
    if (!label) return undefined;

    return { type, label, svg };
  }

  buildOptionForProjectFilter(layoutType: ProjectFilter) {
    switch (layoutType) {
      case ProjectFilter.HEAT_AND_COOL:
        return this.getOption(ProjectFilter.HEAT_AND_COOL, <HeatAndCool />);
      case ProjectFilter.HEAT_ONLY:
        return this.getOption(ProjectFilter.HEAT_ONLY, <HeatOnly />);
      case ProjectFilter.COOL_ONLY:
        return this.getOption(ProjectFilter.COOL_ONLY, <CoolOnly />);
      default:
        return undefined;
    }
  }

  determinedSupportedProjectFilters(): ProjectFilter[] {
    const { supportedProjectFiltersOverride } = this.props;
    if (supportedProjectFiltersOverride && supportedProjectFiltersOverride.length > 0) {
      const projectFilters = FilterUtil.filterListUndefined(
        map(supportedProjectFiltersOverride, (key) => PROJECT_FILTER_CONTENT_OVERRIDE_KEYS[key]),
      );
      return projectFilters;
    }

    return [ProjectFilter.HEAT_AND_COOL, ProjectFilter.HEAT_ONLY, ProjectFilter.COOL_ONLY];
  }

  getOptions() {
    const supportedProjectFilters = this.determinedSupportedProjectFilters();
    return filter(supportedProjectFilters.map(this.buildOptionForProjectFilter)) as TypeOption<ProjectFilter>[];
  }

  render() {
    const { value, className } = this.props;
    const options = this.getOptions();
    if (!options || options.length <= 1) return null;

    return (
      <div className={classNames('ProjectFilterSelector', className)}>
        <TypeSelector
          groupId="projectFilter"
          label="Choose your heating or A/C preference:"
          value={value || ProjectFilter.HEAT_AND_COOL}
          options={options}
          onChange={this.handleProjectFilterChange}
        />
      </div>
    );
  }
}

export default connector(ProjectFilterSelector);
