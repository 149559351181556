import autoBind from 'auto-bind';
import { filter, includes, map } from 'lodash-es';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import NewSVG from '../../public/images/layouts/new.svg';
import RepairSVG from '../../public/images/layouts/repair.svg';
import type { RootState } from '../../redux/slice';
import { formActions, getLayoutType, getServiceType } from '../../redux/slice/formData';
import { getSupportedServicesOverride } from '../../redux/slice/ui';
import { checkAndUpdateProject } from '../../redux/thunks';
import { SERVICE_TYPE_CONTENT_OVERRIDE_KEYS, SERVICE_TYPE_LABELS } from '../../utils/constants';
import { LayoutType, ServiceType } from '../../utils/enums';
import FilterUtil from '../../utils/filterUtil';
import TrackingUtil from '../../utils/trackingUtil';
import TypeSelector, { TypeOption } from '../common/TypeSelector';

type ServiceSelectorOwnProps = {
  className?: string;
};

function mapStateToProps(state: RootState) {
  return {
    value: getServiceType(state),
    layoutType: getLayoutType(state),
    supportedServicesOverride: getSupportedServicesOverride(state),
  };
}

const mapDispatchToProps = {
  updateServiceType: formActions.updateServiceType,
  updateLayoutType: formActions.updateLayoutType,
  checkAndUpdateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ServiceSelectorProps = PropsFromRedux & ServiceSelectorOwnProps;

class ServiceSelector extends React.PureComponent<ServiceSelectorProps> {
  constructor(props: ServiceSelectorProps) {
    super(props);
    autoBind(this);
  }

  componentDidMount(): void {
    this.applySupportedServiceTypes();
  }

  componentDidUpdate(prevProps: Readonly<ServiceSelectorProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.applySupportedServiceTypes();
  }

  private applySupportedServiceTypes() {
    const supportedServiceTypes = this.determineSupportedServiceTypes();

    if (supportedServiceTypes.length < 3) {
      const { value } = this.props;
      if (!includes(supportedServiceTypes, value)) {
        this.handleServiceChange(supportedServiceTypes[0], { doNotTrack: true });
      }
    }
  }

  handleServiceChange(value: ServiceType, config?: { doNotTrack?: boolean }) {
    const { updateServiceType, updateLayoutType, checkAndUpdateProject, layoutType } = this.props;
    updateServiceType(value);
    if (layoutType === LayoutType.REPLACE_EXISTING) {
      updateLayoutType(LayoutType.WHOLE_HOME);
    }

    if (!config?.doNotTrack) {
      TrackingUtil.formUpdate('serviceType', value);
    }

    checkAndUpdateProject();
  }

  getOption(type: ServiceType, svg: React.ReactElement): TypeOption<ServiceType> | undefined {
    const label = SERVICE_TYPE_LABELS[type];
    if (!label) return undefined;

    return { type, label, svg };
  }

  buildOptionForServiceType(serviceType: ServiceType) {
    switch (serviceType) {
      case ServiceType.REPAIR:
        return this.getOption(ServiceType.REPAIR, <RepairSVG />);
      case ServiceType.NEW:
        return this.getOption(ServiceType.NEW, <NewSVG />);
      default:
        return undefined;
    }
  }

  determineSupportedServiceTypes(): ServiceType[] {
    const { supportedServicesOverride } = this.props;
    if (supportedServicesOverride && supportedServicesOverride.length > 0) {
      const serviceTypes = FilterUtil.filterListUndefined(
        map(supportedServicesOverride, (key) => SERVICE_TYPE_CONTENT_OVERRIDE_KEYS[key]),
      );
      return serviceTypes;
    }

    return [ServiceType.NEW, ServiceType.REPAIR];
  }

  getOptions() {
    const supportedServiceTypes = this.determineSupportedServiceTypes();
    return filter(supportedServiceTypes.map(this.buildOptionForServiceType)) as TypeOption<ServiceType>[];
  }

  render() {
    const { value } = this.props;
    const options = this.getOptions();
    if (!options || options.length <= 1) return null;
    const columnSize = Math.max(12 / options.length, 4);

    return (
      <div className="ServiceSelector">
        <TypeSelector
          groupId="serviceType"
          largeCol={columnSize}
          label="How can we assist you today?"
          value={value}
          options={options}
          onChange={this.handleServiceChange}
        />
      </div>
    );
  }
}

export default connector(ServiceSelector);
