import autoBind from 'auto-bind';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { showContactForm } from '../../redux/selectors';
import type { RootState } from '../../redux/slice';
import { getLayoutType, getServiceType } from '../../redux/slice/formData';
import { CONTACT_FORM_EMAIL_TYPES_FOR_LAYOUT_TYPES } from '../../utils/constants';
import { LayoutType, ServiceType } from '../../utils/enums';
import ContactTelephoneNumber from '../common/ContactTelephoneNumber';
import Image from '../common/Image';
import ContactForm from './ContactForm';

function mapStateToProps(state: RootState) {
  return {
    value: getLayoutType(state),
    serviceType: getServiceType(state),
    showContactForm: showContactForm(state),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class LayoutPreview extends React.PureComponent<PropsFromRedux> {
  constructor(props: PropsFromRedux) {
    super(props);
    autoBind(this);
  }

  getImageAndText({ src, alt, title, description }: { src: string; alt: string; title: string; description: string }) {
    return (
      <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row">
        <div className="flex-shrink-0 align-self-center position-relative mb-3 mb-sm-0 mb-md-3 mb-lg-0">
          <Image className="pe-sm-3 pe-md-0 pe-lg-3" src={src} alt={alt} width={150} height={150} />
        </div>
        <div>
          <div className="fw-bold pb-3">{title}</div>
          <div className="text-muted">{description}</div>
        </div>
      </div>
    );
  }

  getTextOnly({ title, description }: { title: string; description: string }) {
    return (
      <>
        <div className="fw-bold pb-3">{title}</div>
        <div className="d-flex flex-column flex-md-row">
          <div className="text-muted">{description}</div>
        </div>
      </>
    );
  }

  getContents() {
    const { value, serviceType, showContactForm } = this.props;

    const emailType = value !== undefined ? CONTACT_FORM_EMAIL_TYPES_FOR_LAYOUT_TYPES[value] : undefined;
    if (showContactForm && emailType) {
      return <ContactForm emailType={emailType} />;
    }

    const verb = serviceType === ServiceType.REPLACE ? 'Replace' : 'Install';
    switch (value) {
      case LayoutType.WHOLE_HOME:
        return this.getTextOnly({
          title: 'Let us recommend your perfect A/C and heating solution.',
          description: 'Get tailored recommendations and upfront prices. Top quality installs guaranteed.',
        });
      case LayoutType.MULTIPLE_ROOMS:
        return this.getImageAndText({
          src: 'multi-split.webp',
          alt: 'multi-split and outdoor unit',
          title: `${verb} mini-splits in 2+ rooms.`,
          description:
            'Enjoy room-by-room temperature control. You can cool and heat up to 5 rooms with a single system.',
        });
      case LayoutType.SINGLE_ROOM:
        return this.getImageAndText({
          src: 'mini-split.webp',
          alt: 'mini-split and outdoor unit',
          title: `${verb} a mini-split in one room.`,
          description:
            'Perfect for an addition, garage, or ADU. A mini-split is the best all-in-one cooling and heating solution for a single room.',
        });
      case LayoutType.UNKNOWN:
        return (
          <>
            <div className="fw-bold pb-3">Prefer to talk through your options with us?</div>
            <div className="d-flex flex-column flex-md-row">
              <div className="text-muted">
                Call us at <ContactTelephoneNumber inline />.
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  }

  render() {
    return (
      <div className="LayoutPreview">
        <div className="highlight rounded p-3">{this.getContents()}</div>
      </div>
    );
  }
}

export default connector(LayoutPreview);
