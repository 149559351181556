import autoBind from 'auto-bind';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { isContactFormSubmitSuccess } from '../../redux/slice/formData';
import { isSaving, isSubmitting } from '../../redux/slice/projects';
import { checkContactForm, submitContactForm } from '../../redux/thunks';
import type { ContactEmailType } from '../../utils/enums';

type SubmitContactFormButtonOwnProps = {
  className?: string;
  emailType: ContactEmailType;
};

function mapStateToProps(store: RootState) {
  return {
    submitSuccess: isContactFormSubmitSuccess(store),
    isSaving: isSaving(store),
    isSubmitting: isSubmitting(store),
  };
}

const mapDispatchToProps = {
  submitContactForm,
  checkRepairForm: checkContactForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SubmitContactFormButtonProps = PropsFromRedux & SubmitContactFormButtonOwnProps;

class SubmitContactFormButton extends React.PureComponent<SubmitContactFormButtonProps> {
  constructor(props: SubmitContactFormButtonProps) {
    super(props);
    autoBind(this);
  }

  onSubmitClick() {
    const { submitContactForm, checkRepairForm, emailType } = this.props;
    const onSuccess = () => submitContactForm(emailType);
    checkRepairForm({ onSuccess });
  }

  render() {
    const { isSubmitting, isSaving, className, submitSuccess } = this.props;
    return (
      <>
        {isSubmitting && (
          <div className="spinner-border text-muted" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <Button
          className={className}
          variant="primary"
          disabled={isSaving || isSubmitting || submitSuccess}
          onClick={this.onSubmitClick}
        >
          Submit
        </Button>
      </>
    );
  }
}

export default connector(SubmitContactFormButton);
